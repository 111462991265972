import {
  Center,
  CenterProps,
  Flex,
  FlexProps,
  FormControl,
  FormControlProps,
  FormLabel,
  IconButton,
  IconButtonProps,
  Text,
  useControllableState,
  UseControllableStateProps,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

export interface QuantityPickerProps extends UseControllableStateProps<number> {
  isDisabled?: boolean
  max?: number
  min?: number
  rootProps?: FormControlProps
  isLoading?: boolean
  hideLabel?: boolean
  label?: string
  buttonProps?: Partial<IconButtonProps>
  quantityProps?: Partial<CenterProps>
  wrapperProps?: Partial<FlexProps>
  value?: number
  onChange?: (newValue: number) => void
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const {
    isDisabled = false,
    min = 0,
    max,
    rootProps,
    isLoading,
    hideLabel,
    label = 'Quantity',
    buttonProps,
    quantityProps,
    wrapperProps,
    value,
    onChange,
    ...rest
  } = props

  const handleDecrement = () => {
    if (value > min) onChange(value - 1)
  }

  const handleIncrement = () => {
    if (max === undefined || value < max) onChange(value + 1)
  }

  const isDecrementDisabled = isDisabled || value <= min || isLoading
  const isIncrementDisabled = isDisabled || (max !== undefined && value >= max) || isLoading

  return (
    <FormControl tabIndex={0} aria-label={`${label} selected is ${value}`} {...rootProps}>
      {hideLabel ? (
        <VisuallyHidden>
          <FormLabel>{label}</FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel fontSize="sm" fontWeight="medium">
          {label}
        </FormLabel>
      )}

      <Flex
        p={1}
        borderRadius="base"
        borderWidth="1px"
        justifyContent="space-between"
        {...wrapperProps}
        height="40px"
        alignItems="center"
        bgColor={isDisabled ? 'var(--semantic-surface-muted, #F3F4F6)' : ''}
        color={isDisabled ? 'var(--semantic-text-muted, #76797E)' : ''}
        border={isDisabled ? '1px solid var(--core-shading-shading-500, #9DA2A8)' : ''}
      >
        <QuantityPickerButton
          onClick={handleDecrement}
          icon={<FiMinus />}
          isDisabled={isDecrementDisabled}
          aria-label="Decrement"
          {...buttonProps}
        />
        <Center {...quantityProps}>
          <Text as="span" userSelect="none" fontWeight={400} fontSize="16px">
            {value > 0 ? value : 1}
          </Text>
        </Center>
        <QuantityPickerButton
          onClick={handleIncrement}
          icon={<FiPlus />}
          isDisabled={isIncrementDisabled}
          aria-label="Increment"
          {...buttonProps}
        />
      </Flex>
    </FormControl>
  )
}

const QuantityPickerButton = (props: IconButtonProps) => (
  <IconButton size="xs" fontSize="sm" variant="ghost" color={'text-primary'} {...props} />
)
