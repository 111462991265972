import { format, parseISO, parse } from 'date-fns'
import { addCAPIFbEvent, getCAPIrootEventsProps } from '../../../../helpers/metaCapiHelper'

export const formatDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'EEE, MMM d')
}
export const getWeekDayFromDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'EEE')
}
export const getDateAndMonthFromDate = ({ dateString, convert = false }) => {
  let dateToFormat = dateString
  if (convert) {
    const [month, day, year] = dateString.split('/')
    dateToFormat = `${year}-${month}-${day}`
  }
  const date = parseISO(dateToFormat)
  return format(date, 'MMM d')
}

function extractGoogleAnalyticsUserIdFromCookie(gaCookie) {
  if (gaCookie) {
    const userIdParts = gaCookie.split('.').slice(-2)
    if (userIdParts.length === 2) {
      return userIdParts.join('_')
    }
  }
  return undefined
}

function getBrowserCookie(cookieName: string) {
  if (typeof document !== 'object' || typeof document.cookie !== 'string') {
    return undefined
  }
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}

export function getGoogleAnalyticsUserIdFromBrowserCookie(cookieName: string) {
  const browserCookie = getBrowserCookie(cookieName)

  if (!browserCookie) {
    return undefined
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie)
}

export const filterChildProductsByAvailability = (childProducts: any) => {
  return childProducts?.filter((childProduct: any) => {
    const isLive = new Date(childProduct?.attributes?.targetGoLiveDateAsDateType) <= new Date()

    const childProductHasInventory = childProduct?.variants?.some((variant: any) => {
      return variant?.availabilityId && variant?.availability?.availableQuantity > 0
    })

    const childProductHasBackorder = childProduct?.variants?.some((variant: any) => {
      return variant?.availabilityId && variant?.availability?.backorderlevel > 0
    })

    return isLive && (childProductHasInventory || childProductHasBackorder)
  })
}

export const getPrice = ({ cartPromotion, priceByEdsCodes, currentChildProduct }: any) => {
  if (cartPromotion) {
    return (
      priceByEdsCodes?.custom?.fields?.basePrice?.centAmount ??
      priceByEdsCodes?.value?.centAmount ??
      currentChildProduct?.price?.centAmount
    )
  } else {
    return currentChildProduct?.price?.centAmount
  }
}

export const callMetaCAPIAddToCartEvent = ({
  totalPrice,
  product,
  currentVariant,
  userCurrency = null,
  userCountry = null,
  userSessionData = null,
  currentChildProduct,
}) => {
  const eventData = {
    events: [
      {
        ...getCAPIrootEventsProps('AddToCart'),
        custom_data: [
          {
            key: 'currency',
            value: userCurrency || 'USD',
          },
          {
            key: 'value',
            value: totalPrice,
          },
          {
            key: 'content_name',
            value: product.name,
          },
          {
            key: 'content_ids',
            value: [currentChildProduct?.attributes?.itemNumber],
          },
          {
            key: 'content_type',
            value: 'product',
          },
        ],
        customer_information: [
          {
            key: 'country',
            value: userCountry || 'US',
          },
        ],
      },
    ],
    partner_agent: 'liveramp',
  }

  addCAPIFbEvent(eventData)
}

export function formatYear(twoDigitYear) {
  // Parse the two-digit year (assuming current century) and get the full year in yyyy format
  const date = parse(twoDigitYear, 'yy', new Date())

  // Return the full year (yyyy) from the parsed date
  return format(date, 'yyyy')
}
