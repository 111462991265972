import { FC } from 'react'
import Image from 'next/image'
import { Box, Button, Flex, Text, Tooltip, useToken } from '@chakra-ui/react'
import type { StyleProps } from '@chakra-ui/react'
import { IoImagesOutline } from 'react-icons/io5'
import { SwatchSelectorProps } from '../../types'

type SwatchWrapperProps = {
  wrap: boolean
  children: React.ReactNode
  colorDisplayName?: string
  value?: string
  wrapperStyle?: StyleProps
  isMobile?: boolean
}
const SwatchWrapper: FC<SwatchWrapperProps> = ({
  wrap,
  children,
  colorDisplayName,
  value,
  wrapperStyle,
  isMobile,
}: SwatchWrapperProps) => {
  return wrap ? (
    <Tooltip
      placement="top"
      rounded="md"
      maxW={{ base: '90vw', sm: 'auto' }}
      mr={{ base: 2, sm: 0 }}
      hasArrow
      isDisabled={isMobile}
      label={
        <Text textStyle={'body-75'} textTransform={'capitalize'}>
          {colorDisplayName ? colorDisplayName.toLocaleLowerCase() : value?.toLocaleLowerCase()}
        </Text>
      }
      {...wrapperStyle}
    >
      {children}
    </Tooltip>
  ) : (
    <Box {...wrapperStyle}>{children}</Box>
  )
}

export const SwatchSelector = ({
  option,
  setSelection,
  isDisabled,
  isSelected,
  renderTooltip = true,
  wrapperStyle,
  isHovered = false,
  isStoreDataLoading,
  isMobile,
}: SwatchSelectorProps) => {
  const [textPrimary, brandSecondary] = useToken('colors', ['text.primary', 'brand.secondary'])
  const { swatch, value, colorDisplayName } = option

  // Separate logic for isDisabled and isStoreDataLoading styles
  const isButtonDisabled = isDisabled
  const isLoading = isStoreDataLoading
  console.log('DEBUG: option.swatchUrl:', option.swatchUrl)
  return (
    <SwatchWrapper
      wrap={renderTooltip}
      colorDisplayName={colorDisplayName}
      value={value}
      wrapperStyle={wrapperStyle}
      isMobile={isMobile}
    >
      <Button
        key={value}
        p={0}
        minW={'auto'}
        w={12}
        h={12}
        borderRadius={'50%'}
        overflow={'hidden'}
        display={'inline-block'}
        position={'relative'}
        cursor={isButtonDisabled || isLoading ? 'not-allowed' : 'pointer'}
        outline={'2px solid'}
        outlineColor={'white'}
        outlineOffset={'-4px'}
        border={'2px solid'}
        borderColor={
          isSelected ? 'secondary.900' : isHovered ? 'primary.300' : isLoading ? 'primary.loading' : 'secondary.100'
        } // Different border for loading state
        backgroundColor={!swatch ? 'brand.muted' : isLoading ? 'loading.background' : 'transparent'} // Background color for loading
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if (!isButtonDisabled && !isLoading) {
            setSelection(value) // Only trigger selection if not disabled or loading
          }
        }}
        isDisabled={isButtonDisabled || isLoading} // Disable button if loading or disabled
        _hover={{
          borderColor: isSelected
            ? 'secondary.900'
            : isHovered
              ? 'primary.300'
              : isLoading
                ? 'primary.loading'
                : 'primary.300', // Hover state for loading
        }}
        _active={{
          borderColor: 'primary.400',
        }}
        _disabled={{
          borderColor: isButtonDisabled ? 'secondary.100' : isLoading ? 'loading.border' : 'secondary.100', // Different disabled style
          backgroundColor: isButtonDisabled ? 'surface.muted' : isLoading ? 'loading.background' : 'surface.primary',
          color: isButtonDisabled ? 'text.muted' : isLoading ? 'text.loading' : 'text.primary',
        }}
        aria-label={value}
        aria-selected={isSelected}
      >
        {/* Overlay to show loading or disabled state visually */}
        <Box
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          zIndex={2}
          bg={
            isButtonDisabled
              ? `linear-gradient(to left top, transparent 47.75%, ${textPrimary} 49.5%, ${textPrimary} 50.5%, transparent 52.25%)`
              : undefined
          }
        />

        {option.swatchUrl ? (
          <Image
            loader={({ src }) => `${src}?$Swatch$`}
            src={option.swatchUrl}
            alt={value ?? ''}
            width={46}
            height={46}
            style={{
              opacity: isButtonDisabled ? 0.6 : 1, // Dim if disabled
              objectFit: 'cover', // Ensures the image covers the circle
              objectPosition: 'center', // Centers the image
              borderRadius: '50%', // Ensures the image is circular
              width: '100%', // Matches the button size
              height: '100%', // Matches the button size
            }}
          />
        ) : (
          <Flex
            role={'img'}
            aria-label={value ?? ''}
            justify={'center'}
            align={'center'}
            width={'full'}
            height={'full'}
            bgColor={'transparent'}
          >
            <IoImagesOutline
              size={'24px'}
              color={brandSecondary}
              opacity={isButtonDisabled || isLoading ? 0.6 : 1} // Dim the icon when loading or disabled
            />
          </Flex>
        )}
      </Button>
    </SwatchWrapper>
  )
}
