import { useMemo } from 'react'
import { OptimizedProduct } from '@Types/product/OptimizedPDPDataSource'

type UseAvailableAttributesOptionsParams = {
  childProducts: OptimizedProduct[]
  textSelectorAttributes: string[]
}

export const useAvailableAttributesOptions = ({
  childProducts,
  textSelectorAttributes,
}: UseAvailableAttributesOptionsParams) => {
  const availableAttributesOptions = useMemo(() => {
    const childProductsAttributes = childProducts?.map((childProduct) =>
      childProduct?.variants?.reduce((acc: { [key: string]: string[] }, variant) => {
        Object.entries(variant.attributes).forEach(([key, value]: [string, string]) => {
          if (
            value &&
            typeof value !== 'object' &&
            textSelectorAttributes.includes(key) &&
            !acc[key]?.includes(value)
          ) {
            acc[key] = acc[key] ? [...acc[key], value] : [value]
          }
        })
        return acc
      }, {}),
    )

    const finalAttributes = childProductsAttributes?.reduce(
      (acc: { [key: string]: string[] }, attributes: { [key: string]: string[] }) => {
        Object.entries(attributes).forEach(([key, value]: [string, string[]]) => {
          acc[key] = acc[key] ? [...acc[key], ...value] : [...value]
        })
        return acc
      },
      {},
    )

    Object.entries(finalAttributes).forEach(([key, value]: [string, string[]]) => {
      finalAttributes[key] = [...new Set(value)].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    })

    return finalAttributes
  }, [childProducts, textSelectorAttributes])

  return availableAttributesOptions
}
