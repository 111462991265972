import { useMemo } from 'react'
import { OptimizedProduct } from '@Types/product/OptimizedPDPDataSource'

export const useGetUniqueValidEdsCodes = (products: OptimizedProduct[]) => {
  return useMemo(() => {
    const edsCodeSet = new Set<string>()

    function searchEdsCode(obj) {
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          if (key === 'edsCode' && obj[key]) {
            edsCodeSet.add(obj[key])
          } else if (typeof obj[key] === 'object') {
            searchEdsCode(obj[key])
          }
        }
      }
    }

    searchEdsCode(products)
    return Array.from(edsCodeSet)
  }, [products])
}
