import React from 'react'
import { Box } from '@chakra-ui/react'
import { AlertBox } from 'composable/components/alert-box'
import { useFormat } from 'helpers/hooks/useFormat'
import { AlertMessage } from './AlertMessage'
import { getDateAndMonthFromDate, getWeekDayFromDate } from '../helpers'

export const StockMessage = ({
  isInStock,
  isLowStock,
  isBackordered,
  availabilityDate,
  firstAvailableChildProduct,
  isDonationPdp,
  isGiftCardPdp,
  areAllAttributesSelected,
  isInCart,
}) => {
  const { formatMessage } = useFormat()

  if (areAllAttributesSelected) {
    return (
      <Box width="100%">
        {isInStock &&
          !isLowStock &&
          AlertMessage(
            formatMessage({
              id: 'product.inStock.info',
            }),
            'success',
            'ui.success.light',
          )}

        {isLowStock &&
          AlertMessage(
            formatMessage({
              id: 'product.lowStock.info',
            }),
            'warning',
            'ui.warning.light',
          )}

        {!isInStock &&
          !isLowStock &&
          isInCart &&
          AlertMessage(
            formatMessage({
              id: 'product.outOfStock.info',
            }),
            'error',
            'ui.error.light',
          )}

        {!isInCart && !isInStock && !isLowStock && isBackordered && availabilityDate && (
          <AlertBox
            description={
              <>
                {formatMessage({
                  id: 'product.backorder.info',
                })}
              </>
            }
            rootProps={{
              bg: 'ui.error.light',
              status: 'warning',
            }}
            closeButtonProps={{ display: 'none' }}
            alertDescriptionProps={{ marginLeft: '15px', marginTop: '2px', display: 'inline-block' }}
          />
        )}
      </Box>
    )
  }
  if (!firstAvailableChildProduct && !isDonationPdp && !isGiftCardPdp) {
    return (
      <Box width="100%">
        {AlertMessage(
          formatMessage({
            id: 'product.outOfStock.info',
          }),
          'error',
          'ui.error.light',
        )}
      </Box>
    )
  }
}
