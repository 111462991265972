import { Box, Button, VStack } from '@chakra-ui/react'
import { Product } from '@Types/product/Product'
import type { Variant } from '@Types/product/Variant'
import { IoBagOutline } from 'react-icons/io5'
import { QuantityPicker } from 'composable/components/quantity-picker'
import { useFormat } from 'helpers/hooks/useFormat'
import { useLocale } from 'frontastic/contexts/localeContext'
import { MAX_GIFT_CARD_AMOUNT, MAX_DONATION_AMOUNT } from './constants'

type PdpMainProps = {
  quantity: number
  onChangeQuantity?: (newValue: number) => void
  quantityMin: number
  quantityMax: number
  areAllAttributesSelected: boolean
  isDonationPdp: boolean
  isGiftCardPdp: boolean
  isWishListLoading: boolean
  isInStock: boolean
  isLowStock: boolean
  isBackordered?: boolean
  availabilityDate?: string
  isLoading: boolean
  productAddedToWishList: boolean
  currentVariant: Variant
  handleChangeQuantityPicker: (n: number) => void
  handleAddToCart: (
    variant: Variant,
    quantity: number,
    giftCardAmount: number | undefined,
    isGiftCard: boolean | undefined,
    isDonation: boolean | undefined,
  ) => void
  handleAddToWishList: () => void
  firstAvailableChildProduct: undefined | Product
  shippingPickupSection?: JSX.Element
  giftCardAmount?: number
  hideQuantitySelector?: boolean
}

export const PdpMain = (props: PdpMainProps) => {
  const {
    quantity,
    quantityMin,
    quantityMax,
    isDonationPdp,
    isGiftCardPdp,
    isWishListLoading,
    isInStock,
    isBackordered,
    isLoading,
    productAddedToWishList,
    currentVariant,
    handleChangeQuantityPicker,
    handleAddToCart,
    handleAddToWishList,
    firstAvailableChildProduct,
    shippingPickupSection,
    giftCardAmount,
    areAllAttributesSelected,
    hideQuantitySelector,
  } = props

  const { country } = useLocale()
  const isGiftCardInternationalUser = (isGiftCardPdp || isDonationPdp) && country !== 'US'
  const { formatMessage } = useFormat({ name: 'common' })
  const maxGCOrDonationAmount = isDonationPdp ? MAX_DONATION_AMOUNT : isGiftCardPdp ? MAX_GIFT_CARD_AMOUNT : undefined

  const disableAddToCartButton =
    (!isInStock && !isBackordered && !isGiftCardPdp && !isDonationPdp) ||
    !areAllAttributesSelected ||
    quantityMax <= 0 ||
    (maxGCOrDonationAmount && giftCardAmount > maxGCOrDonationAmount) ||
    (isGiftCardPdp && !giftCardAmount) ||
    (isDonationPdp && !giftCardAmount)

  return (
    <VStack width="100%" alignItems="flex-start" spacing={4}>
      <Box width="100%">
        {!hideQuantitySelector && (
          <Box width="100%">
            {!isDonationPdp && (
              <QuantityPicker
                isDisabled={!firstAvailableChildProduct}
                value={quantity}
                onChange={(val) => handleChangeQuantityPicker(val)}
                min={quantityMin}
                max={quantityMax}
                buttonProps={{
                  size: 'sm',
                }}
                hideLabel
              />
            )}
          </Box>
        )}
        {shippingPickupSection && <Box width="100%">{shippingPickupSection}</Box>}
      </Box>

      <Box width="100%" gap={3} display="flex" flexDirection="column">
        <Box id="mt_beforeAddToBag"></Box>
        {!isGiftCardInternationalUser && (
          <Button
            className="addToCart"
            size="lg"
            variant={'solid'}
            width={'full'}
            onClick={() => handleAddToCart(currentVariant, quantity, giftCardAmount, isGiftCardPdp, isDonationPdp)}
            isDisabled={disableAddToCartButton}
            isLoading={isLoading}
          >
            <IoBagOutline size={16} />
            <Box textStyle={'ui-button-200'}>{formatMessage({ id: 'action.addToCart' })}</Box>
          </Button>
        )}
        {/* {!isDonationPdp && !isGiftCardPdp && (
          <Button
            size="lg"
            variant={'outline'}
            gap={2}
            width={'full'}
            onClick={handleAddToWishList}
            isLoading={isWishListLoading}
          >
            {productAddedToWishList ? <TiHeartFullOutline size={16} /> : <AiOutlineHeart size={16} />}
            <Box textStyle={'ui-button-200'}>
              {` ${formatMessage({
                id: productAddedToWishList ? 'action.addToWishlistTextAdded' : 'action.addToWishlistText',
              })}`}
            </Box>
          </Button>
        )} */}
      </Box>
      <Box id="mt_afterWishList"></Box>
    </VStack>
  )
}
