import { useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { OptimizedPDPDataSource } from '@Types/product/OptimizedPDPDataSource'
import { getProductForQuickView } from 'frontastic/actions/product'
import { filterChildProductsByAvailability } from '../../frontastic/tastics/composable/product-detail-page/helpers'

export type QuickViewData = Pick<
  OptimizedPDPDataSource,
  'product' | 'childProducts' | 'cartPromotions' | 'priceRange' | 'isProductInStock'
>

export const useQuickViewProduct = ({ key }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: `PdpQuickViewModal-${key}` })
  const [loading, setLoading] = useState(false)

  const fetchProductData = async (key: string) => {
    setLoading(true)
    try {
      const response = (await getProductForQuickView(key)) as QuickViewData

      const filteredChildProducts = filterChildProductsByAvailability(response?.childProducts)
      return { ...response, filteredChildProducts: filteredChildProducts }
    } catch (error) {
      console.error('Error fetching product data', error)
    } finally {
      setLoading(false)
    }
  }

  return { isOpen, onOpen, onClose, fetchProductData, loading }
}
