/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { AspectRatio, Box, HStack, Link, Stack, StackProps, Icon, useToken, Container } from '@chakra-ui/react'
import { getImageAsset } from 'composable/helpers/utils/image-utils'
import { IoChevronForward, IoChevronBack, IoImagesOutline, IoPlayCircleOutline } from 'react-icons/io5'
import { Carousel, CarouselSlide, useCarousel } from './carousel'
import { PDPVideo } from './pdp/components/pdp-video/index'
import { customImageLoaderForDXL } from './general/components/image-optimization'

export interface GalleryProps {
  imagesAndVideos?: ImagesAndVideos[]
  href?: string
  aspectRatio?: number
  rootProps?: StackProps
  style?: GalleryStyle
  selectedSwatch?: string
  onClick?: (number) => void
  notifyCurrentSlideIndex?: (index: number) => void
}

export type ImagesAndVideos = {
  id?: string
  src?: string
  alt?: string
  type?: 'image' | 'video'
}

export type GalleryStyle = 'slide-show' | 'blog' | undefined

export const Gallery = (props: GalleryProps) => {
  const [textPrimary, brandSecondary] = useToken('colors', ['text.primary', 'brand.secondary'])
  const { imagesAndVideos, aspectRatio = 4 / 3, rootProps } = props
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    if (props?.notifyCurrentSlideIndex) {
      props?.notifyCurrentSlideIndex(currentSlide)
    }
  }, [currentSlide])

  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
  })
  const hasPrevious = currentSlide !== 0
  const hasNext = currentSlide < imagesAndVideos.length - 1

  const handelImageClick = (e, handler) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handler()
    }
  }

  if (!imagesAndVideos?.length) {
    return (
      <Stack spacing="4" {...rootProps}>
        <ElementLinkHandler href={props.href}>
          <AspectRatio ratio={aspectRatio} width="100%" bg="gray.200">
            <Container>
              <IoImagesOutline size={'48px'} color={brandSecondary} />
            </Container>
          </AspectRatio>
        </ElementLinkHandler>
      </Stack>
    )
  }

  return (
    <Stack
      key={`gallery-${imagesAndVideos?.length}`} // This is to force a re-render when the selectedSwatch changes. This is needed to corrently render the video scripts.
      width="100%"
      borderRadius="base"
      overflow="hidden"
      spacing={{ base: 6, lg: 10 }}
    >
      <Stack width="100%" borderRadius="base" overflow="hidden" position="relative">
        <Box
          display={{ base: 'none', lg: imagesAndVideos?.length === 1 ? 'none' : 'flex' }}
          justifyContent="space-between"
          position="absolute"
          top="50%"
          pl={4}
          zIndex={2}
        >
          <Icon
            as={IoChevronBack}
            boxSize={12}
            p={{ base: '8px', lg: '12px' }}
            _hover={{
              backgroundColor: hasPrevious ? 'shading.200' : '',
            }}
            backgroundColor="shading.200"
            borderRadius="base"
            onClick={() => slider.current?.prev()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') slider.current?.prev()
            }}
            cursor={hasPrevious ? 'pointer' : 'not-allowed'}
            opacity={hasPrevious ? '1' : '0.5'}
            aria-label="Previous Slide button"
            tabIndex={0}
          />
        </Box>
        <Box
          display={{ base: 'none', lg: imagesAndVideos?.length === 1 ? 'none' : 'flex' }}
          justifyContent="space-between"
          position="absolute"
          top="50%"
          pr={4}
          right={0}
          zIndex={2}
        >
          <Icon
            as={IoChevronForward}
            boxSize={12}
            p={{ base: '8px', lg: '12px' }}
            _hover={{
              backgroundColor: hasNext ? 'shading.200' : '',
            }}
            backgroundColor="shading.200"
            borderRadius="base"
            onClick={() => slider.current?.next()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') slider.current?.next()
            }}
            cursor={hasNext ? 'pointer' : 'not-allowed'}
            opacity={hasNext ? '1' : '0.5'}
            aria-label="Next Slide button"
            tabIndex={0}
          />
        </Box>

        <Carousel ref={ref}>
          {imagesAndVideos?.map((media, i) => {
            if (media.type === 'video') {
              return (
                <CarouselSlide key={`carousel-${i}`} cursor={'pointer'}>
                  <ElementLinkHandler href={props.href}>
                    <AspectRatio
                      ratio={aspectRatio}
                      transition="all 200ms"
                      opacity={currentSlide === i ? 1 : 0.5}
                      _hover={{ opacity: 1 }}
                    >
                      <PDPVideo videoUrl={media.src} id={i} />
                    </AspectRatio>
                  </ElementLinkHandler>
                </CarouselSlide>
              )
            } else {
              const { serverurl, asset, optionalModifier } = getImageAsset(media.src)

              const imageSrc = serverurl + asset
              const imageAlt = media.alt && media.alt !== '' ? media.alt : `Product image ${i + 1}`
              const imageAriaLabel = media.alt && media.alt !== '' ? media.alt : `Product image ${i + 1}`
              const imageOnClick = () => props?.onClick(currentSlide)

              return (
                <CarouselSlide key={`carousel-${i}`} cursor={'zoom-in'}>
                  <ElementLinkHandler href={props.href}>
                    <AspectRatio
                      ratio={aspectRatio}
                      transition="all 200ms"
                      opacity={currentSlide === i ? 1 : 0.5}
                      _hover={{ opacity: 1 }}
                    >
                      <Image
                        loader={({ src, width, quality }) =>
                          /**
                           * This is a custom image loader for DXL images.
                           * The max width required for the main image is 1024px because of the aspect ratio.
                           * If you inspect the image in the desktop you will notice the the max width is 596px.
                           * This is because the image is being stretched to fit the aspect ratio.
                           * Here we are doubling the max width to 1024px to improve the quality of the image in screens with high pixel density.
                           */
                          customImageLoaderForDXL({ src, width: width > 1024 ? 1024 : width, quality })
                        }
                        sizes="50vw"
                        quality={75}
                        src={imageSrc}
                        alt={imageAlt}
                        aria-label={imageAriaLabel}
                        role="img"
                        priority={i === 0}
                        onClick={imageOnClick}
                        onKeyDown={(e) => handelImageClick(e, imageOnClick)}
                        tabIndex={i == 1 ? 0 : -1}
                        fill
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </AspectRatio>
                  </ElementLinkHandler>
                </CarouselSlide>
              )
            }
          })}
        </Carousel>
      </Stack>
      {imagesAndVideos?.length > 1 && (
        <HStack width="fit-content" alignSelf="center">
          {imagesAndVideos?.map((media, index) => (
            <AspectRatio
              ratio={1}
              key={index}
              cursor="pointer"
              boxSize={12}
              border="2px solid"
              borderColor={currentSlide === index ? 'text.primary' : 'surface.border'}
              borderRadius="base"
            >
              {media.type === 'video' ? (
                <Box position="relative" width="100%" height="100%" onClick={() => slider.current?.moveToIdx(index)}>
                  <Image
                    loader={({ src }) => src}
                    src={media.src ? `${media.src}?$sthumb$` : null}
                    alt={`Product Video ${index + 1}`}
                    aria-label={`Product Video ${index + 1}`}
                    tabIndex={0}
                    fill
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    backgroundColor="black"
                    opacity="0.2"
                    zIndex="1"
                  />
                  <Icon
                    as={IoPlayCircleOutline}
                    boxSize={6}
                    color="white"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="2"
                  />
                </Box>
              ) : (
                <Image
                  loader={({ src }) => src}
                  key={index}
                  src={media.src ? `${media.src}?$sthumb$` : null}
                  alt={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                  aria-label={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                  role={'img'}
                  width={48}
                  height={48}
                  onClick={() => slider.current?.moveToIdx(index)}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') slider.current?.moveToIdx(index)
                  }}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'fill',
                  }}
                />
              )}
            </AspectRatio>
          ))}
        </HStack>
      )}
    </Stack>
  )
}

const ElementLinkHandler = (props: { children: JSX.Element; href?: string }) => {
  return props.href ? (
    <Link as={NextLink} href={props.href}>
      {props.children}
    </Link>
  ) : (
    props.children
  )
}
