export const parseHemmingCharge = (selectedHemmingCharge: string) => {
  if (selectedHemmingCharge === 'Unfinished') return

  const customizationDataCuff = selectedHemmingCharge.includes('without') ? 'N' : 'Y'
  const customizationDataInseamMatch = selectedHemmingCharge.match(/\d+/)
  const customizationDataInseam = customizationDataInseamMatch ? customizationDataInseamMatch[0] : null

  if (!customizationDataInseam) {
    throw new Error(`Invalid hemming charge: ${selectedHemmingCharge}`)
  }

  return {
    customizationDataCuff,
    customizationDataInseam,
  }
}

export const isLineItemCustomChargesSameAsSelectedHemmingCharges = (custom: any, selectedHemmingString: string) => {
  if (!custom || !selectedHemmingString) return false

  const { customizationDataInseam, customizationDataCuff } = parseHemmingCharge(selectedHemmingString)
  const lineItemCustomizationDataCuff = custom?.fields?.customizationDataCuff
  const lineItemCustomizationDataInseam = custom?.fields?.customizationDataInseam

  return (
    lineItemCustomizationDataCuff === customizationDataCuff &&
    lineItemCustomizationDataInseam === customizationDataInseam
  )
}
