/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
  StackProps,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { OptimizedPDPDataSource, OptimizedProduct } from '@Types/product/OptimizedPDPDataSource'
import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { Gallery, ImagesAndVideos } from 'composable/components/gallery'
import { DONATION_SKU, GIFT_CARD_SKU, LOW_STOCK_COUNT, SHIPPING_METHODS } from 'composable/components/general/constants'
import { getCartProductByVariantSku, getCartProductQtyByVariantSku } from 'composable/helpers/utils/cart-utils'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { callCAPIEventForPageView } from 'helpers/metaCapiHelper'
import { utagAddToCart, utagView } from 'helpers/tealiumHelper'
import { parseHemmingCharge } from 'helpers_composable/parseHemmingStringIntoData'
import lodashForEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import sum from 'lodash/sum'
import { Toaster } from 'react-hot-toast'
import { Category } from 'shared/types/product'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import { myBag } from 'frontastic/contexts/atgCartContext/helpers/constants'
import { raiseToast } from 'frontastic/contexts/atgCartContext/helpers/utils'
import { useLocale } from 'frontastic/contexts/localeContext'
import { useStore } from 'frontastic/contexts/storeContext'
import useCartCT from 'frontastic/hooks/useCart'
import { DropShipProductMessage } from 'frontastic/tastics/composable/product-detail-page/components/DropShipProductMessage'
import { PdpMain } from 'frontastic/tastics/composable/product-detail-page/components/PdpMain'
import { PdpPrice } from 'frontastic/tastics/composable/product-detail-page/components/PdpPrice'
import { StockMessage } from 'frontastic/tastics/composable/product-detail-page/components/StockMessage'
import {
  allSkuAvailableQuantity,
  areAllAttributesSelectedFunction,
  findInitialChildProduct,
  findUrlSwatchChildProduct,
  findVariantBySelectedAttributes,
  getQuantityMax,
} from 'frontastic/tastics/composable/product-detail-page/components/utils'
import { callMetaCAPIAddToCartEvent } from 'frontastic/tastics/composable/product-detail-page/helpers'
import { useAvailableAttributesOptions } from 'frontastic/tastics/composable/product-detail-page/hooks/use-available-attributes-options'
import { useAvailableAttributesTextSelectors } from 'frontastic/tastics/composable/product-detail-page/hooks/use-available-attributes-text-selectors'
import { HemmingChargesSection } from './components/hemming-charges-section'
import { Scene7ViewerScript } from './components/scene-7/scene-7-script'
import { SwatchContainer } from './components/swatch-container'
import { TextSelector } from './components/text-selector'
import { CustomerReviews } from './customer-reviews/customer-reviews'
import { useGetCartPromotion } from './hooks/useCartPromotion'
import { useFindPriceByEdsCodes } from './hooks/useFindPriceWithValidEds'
import { useGetUniqueValidEdsCodes } from './hooks/useUniqueValidEds'
import SizeChartModal from './size-chart-modal'
import { SWATCH_VIEW_VALUES } from './types'
import { ToggleButtonGroup } from '../../../components/core-ui-controls/ToggleButton'
import { useHemmingChargesPerSegment } from '../../../helpers/hooks/useHemmingChargesPerSegment'
import { useTealium } from '../../../helpers/hooks/useTealium'
import { appendEpsilonScript } from '../../../helpers/utils/epsilonScript'
import { sanitizeProductName } from '../../helpers/utils/string-utils'
import AtgForgotPassword from '../account/atg-forgot-password'
import AtgLoginPage from '../account/atg-login-page'
import { useComposable } from '../composable-provider'
import ProductBadges from '../general/components/productbadges'
import { useCheckIfProductHasBadge } from '../hooks/useCheckIfProductHasBadge/utils'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { LoyaltyPoints } from '../loyalty-points'
import { PayLaterMessage } from '../paylater-message'
import { SwatchSelectorConfiguration, SwatchSelectorGrouped, TextSelectorOption } from '../types'

type QuickViewProductDetailsLayoutProps = OptimizedPDPDataSource & {
  filteredChildProducts?: OptimizedProduct[]
  algoliaQueryId?: string
  productListType?: string
  productImpressionPosition?: number
}

function QuickViewProductDetailsLayout(data: QuickViewProductDetailsLayoutProps) {
  const router = useRouter()
  const toast = useToast()

  const intl = useFormat({ name: 'common' })
  const { cart, addItem, updateItem, getCartDiscountByKey } = useCartCT()
  const { account, loggedIn } = useAccountCT()
  const { country, currency } = useLocale()
  const { cartDrawer } = useComposable()

  const { product, childProducts, filteredChildProducts, cartPromotions, priceRange, isProductInStock } = data

  const _childProducts = filteredChildProducts.length > 0 ? filteredChildProducts : childProducts
  const query = router?.query
  const [algoliaQueryId] = useLocalStorage('algoliaQueryId', '')
  const upcCodeToMatch = Array.isArray(query.slug) ? query.slug[2] : ''

  const { selectedStoreData } = useStore()
  const findUPCVariant = () => {
    if (!upcCodeToMatch) {
      return { variant: null, product: null }
    }
    for (const childProduct of _childProducts || []) {
      for (const variant of childProduct.variants || []) {
        const upcCodes = variant.attributes?.upcCodes
        if (Array.isArray(upcCodes) && upcCodes.includes(upcCodeToMatch) && variant.availableQuantity > 0) {
          return { variant, product: childProduct }
        }
      }
    }
    return { variant: null, product: null }
  }
  const { variant: matchingUPCVariant, product: matchingChildProduct } = findUPCVariant()

  const isGiftCardPdp: boolean = (router.query.gift as string) === 'true'
  const isDonationPdp: boolean = (router.query.donation as string) === 'true'
  const firstAvailableChildProduct = _childProducts?.find((childProduct) =>
    childProduct?.variants?.some((variant: Variant) => variant?.availableQuantity > 0),
  )
  const urlSwatch: string = (query?.swatch as string) || ''
  const urlSwatchChildProduct = findUrlSwatchChildProduct(_childProducts, urlSwatch)
  const initialChildProduct = findInitialChildProduct(
    urlSwatch,
    urlSwatchChildProduct,
    firstAvailableChildProduct,
    _childProducts,
  )
  const initialSwatch = initialChildProduct?.attributes?.ecommColor
  const [currentChildProduct, setCurrentChildProduct] = useState(initialChildProduct)
  const [currentVariant, setCurrentVariant] = useState<Variant>(initialChildProduct?.variants?.[0])
  const [quantity, setQuantity] = useState(1)
  const [giftCardAmount, setGiftCardAmount] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAttributes, setSelectedAttributes] = useState<{ [key: string]: string }>({})
  const [showMissingSelections, setShowMissingSelections] = useState<boolean>(false)
  const [isWishListLoading, setWishListIsLoading] = useState(false)
  const [productAddedToWishList, setProductAddedToWishList] = useState(false)
  const [isLoginModelOpen, setLoginModelOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [algoliaProductAddedToCart, setAlgoliaProductAddedToCart] = useLocalStorage('algoliaProductAddedToCart', [])
  const [selectedSwatch, setSelectedSwatch] = useState<string>(initialSwatch)

  const [selectedHemmingCharge, setSelectedHemmingCharge] = useState<any>()
  const variantCountInCartForShippingOrBOPIS = useMemo(
    () =>
      getCartProductQtyByVariantSku({
        variantSku: currentVariant?.sku,
        cart: cart,
        isBOPISProduct: false,
        userSelectedStoreNumber: selectedStoreData?.custom?.fields?.lastname,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVariant?.sku, cart?.lineItems],
  )

  // In PLP quick view, the shipping method type is always shipping

  const triggerVariantChange = (selectedAttributes: { [key: string]: string }, currentChildProduct?: Product) => {
    setShowMissingSelections(false)
    const selectedVariant = findVariantBySelectedAttributes(selectedAttributes, currentChildProduct)

    const selectedVariantAvailableQuantity = selectedVariant?.availableQuantity
    const maxAvailableQty =
      selectedVariant?.availability?.backorderlevel > 0
        ? selectedVariant.availability.backorderlevel
        : selectedVariantAvailableQuantity - variantCountInCartForShippingOrBOPIS

    if (maxAvailableQty < quantity) {
      setQuantity(maxAvailableQty < 0 ? 0 : maxAvailableQty)
      if (maxAvailableQty > 0) {
        raiseToast(
          myBag,
          intl.formatMessage({
            id: 'checkout.product.maxAvailableQuantity',
            values: { maxAvailableQty },
          }),
          'info',
          toast,
        )
      }
    } else if (quantity <= 0) {
      setQuantity(1) // reinitializing the quantity counter
    }

    const variantChangeCondition = selectedVariant && selectedVariant?.sku !== currentVariant?.sku
    if (variantChangeCondition) setCurrentVariant(selectedVariant)
  }

  const availableAttributesTextSelectorsLabels = {
    segment2: !!product.attributes?.segment2Label ? String(product.attributes?.segment2Label) : '',
    segment3: !!product.attributes?.segment3Label ? String(product.attributes?.segment3Label) : '',
  }

  const textSelectorAttributes = Object.keys(availableAttributesTextSelectorsLabels)

  const availableAttributesOptions = useAvailableAttributesOptions({ childProducts, textSelectorAttributes })

  const availableAttributesTextSelectors = useAvailableAttributesTextSelectors({
    availableAttributesOptions,
    cart,
    currentChildProduct,
    selectedAttributes,
    isBOPISProduct: false,
    userSelectedStoreNumber: selectedStoreData?.custom?.fields?.lastname,
  })

  const textSelectorsMissingSelection = useMemo<string[]>(
    () =>
      Object.values(availableAttributesTextSelectors)
        .filter(
          (textSelector: { attribute?: { value?: string | undefined | null } }) => !textSelector?.attribute?.value,
        )
        .map((textSelector: { attribute?: { label?: string | undefined | null } }) => textSelector?.attribute?.label),
    [availableAttributesTextSelectors],
  )

  const stockIsSet = currentVariant?.availableQuantity !== undefined
  const areAllAttributesSelected = areAllAttributesSelectedFunction(
    availableAttributesTextSelectorsLabels,
    selectedAttributes,
  )

  const isInStock =
    stockIsSet &&
    currentVariant?.availableQuantity > 0 &&
    currentVariant?.availableQuantity - variantCountInCartForShippingOrBOPIS >= quantity &&
    quantity != 0
  const isLowStock =
    stockIsSet &&
    currentVariant?.availableQuantity - variantCountInCartForShippingOrBOPIS <= LOW_STOCK_COUNT &&
    currentVariant?.availableQuantity - variantCountInCartForShippingOrBOPIS > 0 &&
    currentVariant?.availableQuantity - variantCountInCartForShippingOrBOPIS >= quantity

  const handleAddToCart = async (
    variant: Variant,
    quantity: number,
    giftCardAmount?: number,
    isGiftCard: boolean = false,
    isDonation: boolean = false,
  ) => {
    setIsLoading(true)

    if (isGiftCard || isDonation) {
      if (isGiftCard && (giftCardAmount < 25 || giftCardAmount > 500)) return

      const productSku = isGiftCard ? GIFT_CARD_SKU : DONATION_SKU

      await addItem(
        productSku,
        quantity,
        {
          productTitle: product?.name,
          shippingMethod: SHIPPING_METHODS.SHIPPING,
          pickUpStoreId: undefined,
          giftCardAmount,
        },
        cartDrawer,
      )

      setIsLoading(false)
      return
    }

    if (textSelectorsMissingSelection.length > 0) {
      return setShowMissingSelections(true)
    }

    try {
      let lineItem = getCartProductByVariantSku({
        variantSku: variant.sku,
        cart,
        isBOPISProduct: false,
        userSelectedStoreNumber: selectedStoreData?.custom?.fields?.lastname,
      })
      const productPayload = {
        productTitle: product?.name,
        shippingMethod: SHIPPING_METHODS.SHIPPING,
        pickUpStoreId: undefined,
        pickUpStoreName: undefined,
        ...(selectedHemmingCharge &&
          selectedHemmingCharge !== 'Unfinished' && {
            quantity: variantCountInCartForShippingOrBOPIS > 0 && lineItem ? lineItem.length + quantity : quantity,
            customizationDataCuff: parseHemmingCharge(selectedHemmingCharge).customizationDataCuff,
            customizationDataInseam: parseHemmingCharge(selectedHemmingCharge).customizationDataInseam,
          }),
      }

      const hasCustomization = selectedHemmingCharge && selectedHemmingCharge !== 'Unfinished'
      const areCustomizationsEqual =
        hasCustomization &&
        // @ts-ignore
        lineItem?.custom?.fields?.customizationDataCuff === productPayload.customizationDataCuff &&
        // @ts-ignore
        lineItem?.custom?.fields?.customizationDataInseam === productPayload.customizationDataInseam

      if (
        (variantCountInCartForShippingOrBOPIS > 0 && hasCustomization && areCustomizationsEqual) ||
        (variantCountInCartForShippingOrBOPIS > 0 && !hasCustomization)
      ) {
        const newQuantity = lineItem.length + quantity
        await updateItem(lineItem?.[0]?.lineItemId, newQuantity, productPayload, cartDrawer)
      } else {
        await addItem(variant.sku, quantity, productPayload, cartDrawer)
      }
    } catch (ex) {
      console.log(ex)
    }

    callMetaCAPIAddToCartEvent({
      totalPrice: ((currentChildProduct.price.centAmount * quantity) / 100).toFixed(2),
      product,
      currentVariant,
      userCurrency: currency || 'USD',
      userCountry: country || 'US',
      currentChildProduct,
    })
    const breadcrumbs = product?.categories[0]?.breadcrumbs
    let cartSkuTData = JSON.parse(window.localStorage.getItem('cartSkuTealiumData'))
    if (!!cartSkuTData) {
      cartSkuTData.push({
        skuId: variant?.sku,
        pId: product.attributes?.itemNumber,
        site_section: [breadcrumbs?.length ? breadcrumbs[0]?.label : undefined],
        site_sub_section: [breadcrumbs?.length ? breadcrumbs[1]?.label : undefined],
        site_sub_section_level3: [
          breadcrumbs?.length && breadcrumbs[2] && breadcrumbs[2]?.link ? breadcrumbs[2]?.label : undefined,
        ],
        product_category: [breadcrumbs?.length ? breadcrumbs[0]?.label : undefined],
        product_category2: [breadcrumbs?.length ? breadcrumbs[1]?.label : undefined],
        product_category3: [
          breadcrumbs?.length && breadcrumbs[2] && breadcrumbs[2]?.link ? breadcrumbs[2]?.label : undefined,
        ],
        ...JSON.parse(localStorage.getItem('lastProductClicked')),
      })
      window.localStorage.setItem('cartSkuTealiumData', JSON.stringify(cartSkuTData))
    } else {
      window.localStorage.setItem(
        'cartSkuTealiumData',
        JSON.stringify([
          {
            skuId: variant?.sku,
            pId: currentChildProduct.attributes?.itemNumber,
            site_section: [breadcrumbs?.length ? breadcrumbs[0]?.label : undefined],
            site_sub_section: [breadcrumbs?.length ? breadcrumbs[1]?.label : undefined],
            site_sub_section_level3: [
              breadcrumbs?.length && breadcrumbs[2] && breadcrumbs[2]?.link ? breadcrumbs[2]?.label : undefined,
            ],
            product_category: [breadcrumbs?.length ? breadcrumbs[0]?.label : undefined],
            product_category2: [breadcrumbs?.length ? breadcrumbs[1]?.label : undefined],
            product_category3: [
              breadcrumbs?.length && breadcrumbs[2] && breadcrumbs[2]?.link ? breadcrumbs[2]?.label : undefined,
            ],
            ...JSON.parse(localStorage.getItem('lastProductClicked')),
          },
        ]),
      )
    }
    utagAddToCart({
      product,
      currentVariant,
      currentChildProduct,
      variant,
      breadcrumbs,
      qty: quantity,
      isLoggedIn: loggedIn,
      userDetails: account,
      cartData: cart,
      algoliaQueryId,
    })

    setQuantity(1)
    setIsLoading(false)
    const productAdded = algoliaProductAddedToCart
    productAdded.push({
      item_id: currentChildProduct?.attributes?.itemNumber,
      skuId: variant?.sku,
      algolia_query_id: algoliaQueryId?.trim()
        ? `${currentChildProduct?.attributes?.itemNumber} ${algoliaQueryId?.trim()}`
        : '',
      breadcrumbs: breadcrumbs,
      product_list: [data?.productListType || ''],
      product_impression_position: [data?.productImpressionPosition || ''],
    })

    setAlgoliaProductAddedToCart(productAdded)
  }

  const handleAddToWishList = async () => {
    if (textSelectorsMissingSelection.length > 0) {
      setShowMissingSelections(true)
    } else {
      setShowMissingSelections(false)
      if (loggedIn) {
        // TODO: to handle with CT wishlist
        // addToWishlistFn()
      } else {
        setLoginModelOpen(true)
      }
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
    }
  }

  const closeLoginModal = (obj) => {
    if (obj?.close == 'byClick') {
      setLoginModelOpen(false)
    }
  }

  useEffect(() => {
    callBackAfterLogin()
  }, [account?.firstName])

  const callBackAfterLogin = () => {
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
    }
  }

  const addToWishlistFn = async () => {
    setWishListIsLoading(true)
    // TODO: to handle with CT wishlist
    // await addToWishlistLegacyCart(
    //   currentVariant?.attributes?.itemNumber,
    //   currentVariant?.sku,
    //   quantity,
    //   product.name,
    // ).then(() => {
    //   setWishListIsLoading(false)
    //   setProductAddedToWishList(true)
    //   utagAddToWishlist({
    //     product,
    //     currentVariant,
    //     qty: quantity,
    //     userSessionData,
    //     cartData,
    //     algoliaQueryId: data?.algoliaQueryId || '',
    //   })
    // })
  }

  const [cartDiscountByEdsCode, setCartDiscountByEdsCode] = useState<any>()
  const allUniqueValidEdsCodes = useGetUniqueValidEdsCodes(childProducts)
  const cartPromotion = useGetCartPromotion(cartPromotions, allUniqueValidEdsCodes)
  const saleEvent = childProducts
    ?.find((product) => product?.variants?.some((variant) => variant?.saleEvent))
    ?.variants?.find((variant) => variant?.saleEvent)?.saleEvent

  let priceByEdsCodes
  if (allUniqueValidEdsCodes.length > 0) {
    if (cartDiscountByEdsCode?.value?.permyriad) {
      priceByEdsCodes = cartDiscountByEdsCode.value.permyriad
    } else if (Array.isArray(cartDiscountByEdsCode?.value?.money)) {
      priceByEdsCodes = cartDiscountByEdsCode.value.money[0]?.centAmount
    } else {
      priceByEdsCodes = cartDiscountByEdsCode?.value?.money?.centAmount
    }
  }

  const fetchAllCartDiscountCodes = async () => {
    await getCartDiscountByKey(allUniqueValidEdsCodes[0]).then((res) => {
      if (!res.error) {
        const cartDiscount = res?.data
        if (cartDiscount?.isActive) {
          setCartDiscountByEdsCode(cartDiscount)
        }
      }
    })
  }

  useMemo(() => {
    if (allUniqueValidEdsCodes.length > 0) {
      fetchAllCartDiscountCodes()
    }
  }, [allUniqueValidEdsCodes])

  const availableSwatches = useMemo(() => {
    const initialAccumulator = {
      attribute: {
        label: 'Color',
        value: currentChildProduct?.attributes?.ecommColor,
        colorDisplayName: currentChildProduct?.attributes?.colorDisplayName ?? undefined,
      },

      options: [],
    }

    if (filteredChildProducts.length === 0) {
      return undefined
    }

    return filteredChildProducts?.reduce((acc: SwatchSelectorConfiguration, childProduct) => {
      const swatchValue = childProduct?.attributes?.ecommColor
      const swatch = childProduct?.swatch
      const swatchUrl = childProduct?.attributes?.swatchUrl

      const isSelectedAttributesMatchingVariantAvailable = findVariantBySelectedAttributes(
        selectedAttributes,
        childProduct,
      )

      const basePrice = childProduct?.priceCustomFields?.fields?.basePrice
      const discountedPrice = childProduct?.variants?.[0]?.discountedPrice
      const price = childProduct?.price

      const finalSellingPrice = discountedPrice ? discountedPrice : price
      const finalOriginalPrice =
        discountedPrice && discountedPrice?.centAmount < price?.centAmount
          ? price
          : basePrice?.centAmount > price?.centAmount
            ? basePrice
            : undefined

      const discountAsPerValues = Math.floor(
        ((basePrice.centAmount - finalSellingPrice.centAmount) / basePrice.centAmount) * 100,
      )

      const swatchOption = {
        value: swatchValue,
        colorDisplayName: childProduct?.variants?.[0]?.attributes?.colorDisplayName ?? undefined,
        inStock: {
          ...isSelectedAttributesMatchingVariantAvailable,
          allSkuAvailableQuantity: allSkuAvailableQuantity(childProduct?.variants),
        },
        swatchUrl,
        swatch,
        priceGroup: {
          price: finalSellingPrice,
          basePrice: finalOriginalPrice,
          discountPercent: discountAsPerValues || 0,
        },
      }

      const newOptions = [...acc.options, swatchOption]
      return { ...acc, options: newOptions }
    }, initialAccumulator)
  }, [_childProducts, selectedAttributes, currentChildProduct?.variants])

  const swatchSelectorGroupedByPrice = useMemo(() => {
    if (!availableSwatches) return []

    const swatchSelectorGroupedByDiscounts = groupBy(availableSwatches?.options, 'priceGroup.price.centAmount')
    const mappedSwatchSelectorGrouped = mapKeys(swatchSelectorGroupedByDiscounts, (value, key) => {
      return key === 'undefined' ? '0' : key
    }) as SwatchSelectorGrouped

    return lodashForEach(mappedSwatchSelectorGrouped, (swatchSelector) =>
      swatchSelector.sort((swatch: any) => (swatch?.inStock ? -1 : 1)),
    )
  }, [availableSwatches])

  const setSelectedAttribute = (attribute: string, value: string) => {
    setSelectedAttributes((prev) => {
      const isAttributeAlreadySelected = prev[attribute] === value

      if (isAttributeAlreadySelected) {
        const newSelectedAttributes = { ...prev }
        delete newSelectedAttributes[attribute]
        return newSelectedAttributes
      } else {
        return { ...prev, [attribute]: value }
      }
    })
  }

  const triggerSwatchChange = (selectedSwatch: string) => {
    setShowMissingSelections(false)
    const selectedChildProduct = _childProducts?.find((childProduct: Product) => {
      return childProduct.attributes?.ecommColor === selectedSwatch
    })

    const swatchChangeCondition =
      selectedChildProduct && selectedChildProduct?.productId !== currentChildProduct?.productId

    if (swatchChangeCondition) {
      setCurrentChildProduct(selectedChildProduct)
      setSelectedAttributes({}) // Clear all selected attributes since the new Variant may not have available inventory
    }

    triggerVariantChange(selectedAttributes, selectedChildProduct)
  }

  useEffect(() => {
    if (matchingUPCVariant) {
      setCurrentVariant(matchingUPCVariant)
      setCurrentChildProduct(matchingChildProduct)
      setSelectedSwatch(matchingChildProduct.attributes?.ecommColor)
    } else {
      setCurrentVariant(initialChildProduct?.variants?.[0])
      setSelectedSwatch(initialSwatch)
    }
  }, [product.slug, matchingUPCVariant, matchingChildProduct])

  useEffect(() => {
    triggerVariantChange(selectedAttributes, currentChildProduct)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributes])

  useEffect(() => {
    triggerSwatchChange(selectedSwatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwatch])

  useEffect(() => {
    setQuantity(1)
  }, [product?.key, _childProducts])

  const isBackordered = currentVariant?.availability?.backorderlevel > 0
  const availableForOrder =
    currentVariant?.availability?.availableQuantity + currentVariant?.availability?.backorderlevel
  const isInCart = variantCountInCartForShippingOrBOPIS - availableForOrder === 0

  const quantityMax = getQuantityMax({
    currentVariant,
    isBackordered,
    stockIsSet,
    variantCountInCartForShippingOrBOPIS,
    isBOPISProduct: false,
    BOPISQty: 0,
  })

  if (!product || !currentChildProduct || !currentVariant) return null

  return (
    <>
      <QuickViewProductContent
        title={product?.name}
        productId={product.productId}
        description={product.description}
        isLoaded={true}
        isHemmingChargeable={!!product?.attributes?.isHemmingChargeable}
        setSelectedHemmingCharge={setSelectedHemmingCharge}
        selectedHemmingCharge={selectedHemmingCharge}
        currentVariant={currentVariant}
        currentChildProduct={currentChildProduct}
        product={product}
        priceRange={data?.priceRange || ''}
        algoliaQueryId={data?.algoliaQueryId || ''}
        isInStock={isInStock}
        isLowStock={isLowStock}
        isBackordered={isBackordered}
        availabilityDate={currentVariant.availability?.availabilitydate}
        firstAvailableChildProduct={firstAvailableChildProduct}
        isDonationPdp={isDonationPdp}
        isGiftCardPdp={isGiftCardPdp}
        areAllAttributesSelected={areAllAttributesSelected}
        isInCart={isInCart}
        main={
          <PdpMain
            quantity={quantity}
            quantityMin={1}
            quantityMax={quantityMax}
            areAllAttributesSelected={areAllAttributesSelected}
            isDonationPdp={isDonationPdp}
            isGiftCardPdp={isGiftCardPdp}
            giftCardAmount={giftCardAmount}
            isWishListLoading={isWishListLoading}
            isInStock={isInStock}
            isLowStock={isLowStock}
            isBackordered={isBackordered}
            isLoading={isLoading}
            productAddedToWishList={productAddedToWishList}
            currentVariant={currentVariant}
            handleChangeQuantityPicker={setQuantity}
            handleAddToCart={handleAddToCart}
            handleAddToWishList={handleAddToWishList}
            firstAvailableChildProduct={firstAvailableChildProduct}
          />
        }
        price={
          <PdpPrice
            currentChildProduct={currentChildProduct}
            currentVariant={currentVariant}
            swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
            cartPromotion={cartPromotion}
            priceRange={priceRange}
            priceByEdsCodes={priceByEdsCodes}
            productSaleEvent={saleEvent}
          />
        }
        itemPriceValue={parseFloat(
          CurrencyHelpers.formatForCurrency(currentChildProduct?.price?.centAmount).replace(/[^0-9.-]+/g, ''),
        )}
        textSelectors={availableSwatches ? availableAttributesTextSelectors : undefined}
        textSelectorsLabels={availableSwatches ? availableAttributesTextSelectorsLabels : undefined}
        childProducts={childProducts}
        showMissingSelections={showMissingSelections}
        selectedAttributes={selectedAttributes}
        mainStackProps={{
          position: 'sticky',
          height: 'fit-content',
          top: '12',
        }}
        stackProps={{
          direction: { base: 'column-reverse', lg: 'row-reverse' },
        }}
        isProductInStock={isProductInStock}
        swatchSelector={availableSwatches}
        setSelectedAttribute={setSelectedAttribute}
        setSelectedSwatch={setSelectedSwatch} // added
        swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
      />
      <AtgLoginPage isOpen={isLoginModelOpen} onClose={closeLoginModal} toggleForgotPassword={toggleForgotPassword} />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
    </>
  )
}

type TextSelectorProps = {
  attribute: {
    label: string
    value: string
  }
  options: TextSelectorOption[]
}

interface QuickViewProductContentProps {
  productId: string
  description: string
  isLoaded: boolean
  isHemmingChargeable: boolean
  setSelectedHemmingCharge: any
  selectedHemmingCharge: any
  main: JSX.Element
  price: JSX.Element
  itemPriceValue: number
  title: string
  stackProps?: StackProps
  mainStackProps?: StackProps
  childProducts?: OptimizedProduct[]
  currentChildProduct?: OptimizedProduct
  currentVariant?: Variant
  textSelectors?: {
    [key: string]: TextSelectorProps
  }
  textSelectorsLabels?: {
    [key: string]: string
  }
  setSelectedAttribute?: (attribute: string, value: string) => void
  selectedAttributes?: {
    [key: string]: string
  }
  showMissingSelections?: boolean
  isProductInStock?: boolean
  product?: Product
  swatchSelector?: SwatchSelectorConfiguration
  swatchSelectorGroupedByPrice?: SwatchSelectorGrouped | any[]
  category?: Category
  setSelectedSwatch?: (value: string) => void
  priceRange?: string
  algoliaQueryId?: string
  isInStock?: boolean
  isLowStock?: boolean
  isBackordered?: boolean
  isInCart?: boolean
  availabilityDate?: string
  firstAvailableChildProduct?: any
  isDonationPdp?: boolean
  isGiftCardPdp?: boolean
  areAllAttributesSelected?: boolean
}

const QuickViewProductContent = ({
  title,
  price,
  main,
  productId,
  isLoaded,
  isHemmingChargeable,
  setSelectedHemmingCharge,
  selectedHemmingCharge,
  mainStackProps,
  currentVariant,
  textSelectors,
  textSelectorsLabels,
  selectedAttributes,
  showMissingSelections,
  isProductInStock,
  category,
  product,
  priceRange,
  currentChildProduct,
  swatchSelector,
  setSelectedAttribute,
  swatchSelectorGroupedByPrice,
  setSelectedSwatch,
  algoliaQueryId,
  isInStock,
  isLowStock,
  isBackordered,
  isInCart,
  availabilityDate,
  firstAvailableChildProduct,
  isDonationPdp,
  isGiftCardPdp,
  areAllAttributesSelected,
}: QuickViewProductContentProps) => {
  const [swatchViewType, setSwatchViewType] = useState<string>(SWATCH_VIEW_VALUES.GRID)
  const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false)
  const [isDenimModalOpen, setIsDenimModalOpen] = useState(false)
  const [isRiseModalOpen, setIsRiseModalOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  const { account, accountLoading } = useAccountCT()
  const { country, currency } = useLocale()
  const { constructUtagData } = useTealium()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const intl = useFormat({ name: 'common' })
  const [isUtagViewCalled, setIsUtagViewCalled] = useState(false)
  const router = useRouter()
  const path = router.asPath
  const { currentPDPVariant, isStoreDataLoading } = useStore()
  const { formatMessage } = useFormat({ name: 'common' })
  const isDropShipProduct = currentChildProduct?.attributes?.isDropShip
  const isShowPromoExclusion = product?.attributes?.showPromoExclusion
  const hasEdsPromotion = Boolean(price?.props?.cartPromotion)

  const promoExclusionMessage = intl.formatMessage({ id: 'pdp.promotionexclution.message' })

  const handleViewFullDetails = () => {
    localStorage?.setItem('lastProductSlugVisited', product.slug)

    currentChildProduct?.attributes?.ecommColor
      ? router.push(`/p/${product.slug}?swatch=${currentChildProduct?.attributes?.ecommColor}`)
      : router.push(`/p/${product.slug}`)
  }

  const handleOpenSizeChartModal = () => {
    setIsSizeChartModalOpen(true)
  }

  const handleCloseSizeChartModal = () => {
    setIsSizeChartModalOpen(false)
  }

  const handleOpenDenimModal = () => {
    setIsDenimModalOpen(true)
  }

  const handleCloseDenimModal = () => {
    setIsDenimModalOpen(false)
  }

  const handleOpenRiseModal = () => {
    setIsRiseModalOpen(true)
  }

  const handleCloseRiseModal = () => {
    setIsRiseModalOpen(false)
  }

  const updateCurrentImageIndex = (index: number) => {
    setCurrentImageIndex(index)
  }

  const productVideos: ImagesAndVideos[] = extractVideosFromParentProduct(product)

  const currentImages = useMemo(
    () =>
      currentChildProduct?.images?.map((img: string, id: number) => ({
        id: `${currentVariant?.sku}-${id}`,
        src: img,
        alt: currentVariant?.sku,
        type: 'image',
      })),
    [currentChildProduct?.images, currentVariant?.sku],
  )

  const getBrandName = (product: any) => {
    let bName = ''
    if (product?.brand) {
      bName = product?.brand
    }
    if (product?.attributes?.brand) {
      bName = product?.attributes?.brand
    }
    return bName
  }

  const getCatName = (category: any, index) => {
    index = index - 1
    if (category?.breadcrumbs[index]) {
      const label = category?.breadcrumbs[index].label
      return label ? label : null
    }
    return null
  }

  const getAllAvailableSizes = () => {
    const values = textSelectors
      ? Object.values(textSelectors).flatMap((textSelector) => textSelector?.options?.map((option) => option.value))
      : []
    return values
  }

  const tealiumUtagView = (category) => {
    const utag_data = constructUtagData(path, {
      tealium_event: 'product_view',
      product_algolia_query_id: [algoliaQueryId ?? ''],
      cm_page_id: 'Product: ' + title + ' (' + product?.key + ')',
      cm_category_id: category?.breadcrumbs?.length
        ? `/c/${category?.breadcrumbs[category?.breadcrumbs?.length - 1]?.link}`
        : null,
      page_type: 'product',
      page_name: category?.breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > ') + ` > ${product?.slug}`,
      page_category: 'product',
      representative_sku_id: [currentVariant?.sku],
      product_variant: [],
      page_url: path,
      product_id: [product.key],
      attentiveId: `${product.key}`,
      product_name: [title],
      product_brand: [product.attributes?.brand ?? null],
      product_color: [],
      product_fitsize: '',
      product_available_sizes: getAllAvailableSizes(),
      product_list_price: [(currentChildProduct?.price?.centAmount / 100).toFixed(2)],
      product_unit_price: [(currentChildProduct?.price?.centAmount / 100).toFixed(2)],
      product_discount_amount: [
        currentVariant?.discountedPrice
          ? ((currentVariant?.discountedPrice?.centAmount - currentChildProduct?.price?.centAmount) / 100).toFixed(2)
          : 0,
      ],
      site_section: [category?.breadcrumbs[0]?.label ?? undefined],
      site_sub_section: [category?.breadcrumbs[1]?.label ?? undefined],
      site_sub_section_level3: [
        category?.breadcrumbs[2] && category?.breadcrumbs[2]?.link ? category?.breadcrumbs[2]?.label : undefined,
      ],
      product_category: [category?.breadcrumbs[0]?.label ?? undefined],
      product_category2: [category?.breadcrumbs[1]?.label ?? undefined],
      product_category3: [
        category?.breadcrumbs[2] && category?.breadcrumbs[2]?.link ? category?.breadcrumbs[2]?.label : undefined,
      ],
      event_type: 'product details',
      site_breadcrumb: category?.breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > '),
      site_id: 'dxl',
      customer_email: account?.email || undefined,
      customer_postal_code: account?.addresses?.[0]?.postalCode || undefined,
      customer_id: account?.accountId || undefined,
      crm_customer_number: account?.accountId || undefined,
    })

    // if (path.includes('/p/')) {
    utagView(utag_data, country, currency)
    // }
    callCAPIEventForPageView(country)
    setIsUtagViewCalled(true)
  }

  const isBackOrderLevel = (currentVariant: Variant) => {
    // Todo to add backorderlevel check currently this value is not available
    return currentVariant?.isOnStock && currentVariant?.availableQuantity > 0
  }

  const isAfterPayEligible = useMemo(() => {
    return !!!currentChildProduct?.attributes?.isDropShip && isBackOrderLevel(currentVariant)
  }, [currentChildProduct, currentVariant])

  const swatchSelectorGroupedByPriceSize = sum(
    map(swatchSelectorGroupedByPrice as any, (swatch: any) => {
      return swatch.length
    }),
  )

  const showDenimModal =
    typeof product.attributes.primaryCategory === 'string' &&
    product.attributes.primaryCategory
      .toLowerCase()
      .split('=>')
      .some((category) => category.includes('jeans') || category.includes('pants'))

  const order = ['Size', 'Waist', 'Rise', 'Inseam', 'Cuff', 'Shoe Size', 'Shoe Width']

  const sortedTextSelectors = useMemo(() => {
    return (
      textSelectors &&
      Object.values(textSelectors).sort((a, b) => {
        const labelA = textSelectorsLabels[a.attribute.label]
        const labelB = textSelectorsLabels[b.attribute.label]
        let indexA = order.indexOf(labelA) === -1 ? order.length : order.indexOf(labelA)
        let indexB = order.indexOf(labelB) === -1 ? order.length : order.indexOf(labelB)
        return indexA - indexB
      })
    )
  }, [textSelectors, textSelectorsLabels])

  const { hasBadge, badgeValues } = useCheckIfProductHasBadge(
    product,
    false,
    currentPDPVariant,
    router.asPath,
    currentChildProduct,
  )
  const productBrandName = getBrandName(product)
  const defaultSizeChartModalSlugName = 'size_chart_modal'
  const defaultDenimModalSlugName = 'denim_modal'
  const defaultRiseModalSlugName = 'rise_modal'
  const brandSpecificSizeChartModalSlugName = sanitizeProductName(productBrandName, defaultSizeChartModalSlugName)
  const brandSpecificDenimSlugName = sanitizeProductName(productBrandName, defaultDenimModalSlugName)
  const brandSpecificRiseSlugName = sanitizeProductName(productBrandName, defaultRiseModalSlugName)
  const sizeChartSlugNameArray = [defaultSizeChartModalSlugName, brandSpecificSizeChartModalSlugName]
  const denimSlugNameArray = [defaultDenimModalSlugName, brandSpecificDenimSlugName]
  const riseSlugNameArray = [defaultRiseModalSlugName, brandSpecificRiseSlugName]

  const { hemmingChargesPerSegment } = useHemmingChargesPerSegment({
    isHemmingChargeable,
    selectedAttributes,
  })
  useEffect(() => {
    if (!isUtagViewCalled && !accountLoading) {
      tealiumUtagView(category)
    }
  }, [accountLoading, isUtagViewCalled])

  useEffect(() => {
    const epsilonConfig = {
      dtm_fid: 5419,
      dtm_promo_id: 5,
      dtmc_department: getCatName(category, 1),
      dtmc_category: getCatName(category, 2),
      dtmc_sub_category: getCatName(category, 3),
      dtmc_product_id: productId,
      dtmc_brand: getBrandName(product),
      dtm_user_id: account?.externalId || null,
      dtm_email_hash: account?.email || null,
    }
    appendEpsilonScript(epsilonConfig, 'epsilonProduct')
  }, [category])

  return (
    <>
      <Box data-productSkuId={currentVariant?.sku}>
        <Toaster />
        <Container maxW="container.2xl" mx="auto" px={2}>
          <Stack flexDirection={{ base: 'column', md: 'row' }} gap={1}>
            <ImageSection
              productId={product.productId}
              images={currentImages}
              videos={productVideos}
              onClick={onOpen}
              updateCurrentImageIndex={updateCurrentImageIndex}
            />
            <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '10', lg: '16', xl: '24' }}>
              <Stack
                maxH={{ base: 'initial', lg: '95vh' }}
                maxW={{ lg: 'md' }}
                overflow={'hidden'}
                justify="flex-start"
                width="100%"
                layerStyle={'no-scroll-bar'}
                {...mainStackProps}
              >
                <Stack m={0} overflowY={'scroll'} gap={{ base: 5, md: 6 }}>
                  <Stack gap={2}>
                    <Box display={'flex'} gap={2}>
                      <Box width={'100%'}>
                        <Flex justifyContent="space-between" gap={2} pb={4}>
                          <Box display="flex">{hasBadge && <ProductBadges badgeValues={badgeValues} />}</Box>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                          <SkeletonText textStyle={'eyebrow-75'} color={'text.muted'} isLoaded={isLoaded}>
                            {product?.attributes?.brand}
                          </SkeletonText>
                          <LoyaltyPoints pricedata={currentChildProduct.price.centAmount} textAlign={'right'} />
                        </Flex>
                        <Heading>
                          <Skeleton textStyle="heading-desktop-200" isLoaded={isLoaded} mt={2}>
                            {title}
                          </Skeleton>
                        </Heading>
                      </Box>
                    </Box>

                    {product && (
                      <Skeleton isLoaded={isLoaded}>
                        <Box id="prQuickView" paddingLeft={0} marginLeft={0} pointerEvents="none" cursor="default">
                          <CustomerReviews product={product} />
                        </Box>
                      </Skeleton>
                    )}
                    <SkeletonText isLoaded={isLoaded} textStyle={'body-100-300'}>
                      {price}
                    </SkeletonText>
                    {country === 'US' && (
                      <Skeleton isLoaded={isLoaded}>
                        {priceRange || (currentChildProduct?.price?.centAmount ?? 0) > 150000 ? (
                          <PayLaterMessage totalPrice={'100'}></PayLaterMessage>
                        ) : (
                          <PayLaterMessage totalPrice={currentChildProduct?.price?.centAmount}></PayLaterMessage>
                        )}
                      </Skeleton>
                    )}
                  </Stack>

                  {isProductInStock && (
                    <>
                      <Skeleton isLoaded={isLoaded}>
                        <Flex h={5} mb={3} alignItems={'center'}>
                          <Flex alignItems="flex-start" flex={1}>
                            <Text textStyle={'heading-desktop-75'} pe={2}>
                              {swatchSelector?.attribute?.label}
                            </Text>
                            <Text textStyle={'body-75'} textTransform={'capitalize'} lineHeight="120%">
                              {swatchSelector?.attribute?.colorDisplayName
                                ? swatchSelector?.attribute?.colorDisplayName.toLowerCase()
                                : swatchSelector?.attribute?.value.toLowerCase()}
                            </Text>
                          </Flex>
                          {swatchSelectorGroupedByPriceSize > 1 && (
                            <Flex alignItems="flex-end">
                              <ToggleButtonGroup
                                onChange={(v) => setSwatchViewType(v[0] as string)}
                                allowNone={false}
                                variant={'outline'}
                                exclusive
                                initialIndex={0}
                              >
                                <Button value={SWATCH_VIEW_VALUES.GRID}>Grid</Button>
                                <Button value={SWATCH_VIEW_VALUES.LIST}>List</Button>
                              </ToggleButtonGroup>
                            </Flex>
                          )}
                        </Flex>
                        {swatchSelectorGroupedByPrice && (
                          <SwatchContainer
                            swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
                            swatchViewType={swatchViewType}
                            swatchSelector={swatchSelector}
                            isStoreDataLoading={isStoreDataLoading}
                            setSelectedSwatch={setSelectedSwatch}
                          />
                        )}
                      </Skeleton>
                      {sortedTextSelectors?.map((textSelector, i) => {
                        const { attribute, options } = textSelector
                        return (
                          <Skeleton key={`text-selector-${i}`} isLoaded={isLoaded}>
                            <Flex h={5} mb={3} alignItems={'center'}>
                              {textSelectorsLabels[attribute.label] === 'Rise' ? (
                                <Box
                                  display={'flex'}
                                  gap={'4px'}
                                  justifyContent={'left'}
                                  alignItems={'center'}
                                  onClick={handleOpenRiseModal}
                                  cursor="pointer"
                                >
                                  <Box fontSize={'14px'} textStyle="body-75" fontWeight={600}>
                                    {intl.formatMessage({
                                      id: 'text.sizeChart.rise',
                                      defaultMessage: 'Rise',
                                      values: { brandName: '' },
                                    })}
                                  </Box>
                                  <Icon as={InfoOutlineIcon} w={4} h={4} />
                                </Box>
                              ) : (
                                <Text textStyle={'heading-desktop-75'} pe={2} textTransform={'capitalize'}>
                                  {textSelectorsLabels[attribute.label]}
                                </Text>
                              )}
                              {attribute?.value ? (
                                <Text textStyle={'body-75'} ml={1}>
                                  {attribute.value}
                                </Text>
                              ) : (
                                showMissingSelections && (
                                  <Text textStyle={'body-75'} color={'text.danger'} ml={1}>
                                    {intl.formatMessage({
                                      id: 'product.pleaseSelect',
                                      values: { attribute: textSelectorsLabels[attribute.label] },
                                    })}
                                  </Text>
                                )
                              )}
                            </Flex>

                            <Flex gap={2.5} flexWrap={'wrap'}>
                              {options.map((option, index) => {
                                return (
                                  <Box key={`swatch-${index}`}>
                                    <TextSelector
                                      option={option}
                                      isSelected={option.value === attribute.value}
                                      isDisabled={!option?.inStock}
                                      setSelection={() => {
                                        setSelectedAttribute(attribute.label, option.value)
                                      }}
                                      isStoreDataLoading={false}
                                    />
                                  </Box>
                                )
                              })}
                            </Flex>
                          </Skeleton>
                        )
                      })}

                      {hemmingChargesPerSegment && (
                        <HemmingChargesSection
                          hemmingChargesPerSegment={hemmingChargesPerSegment}
                          selectedHemmingCharge={selectedHemmingCharge}
                          setSelectedHemmingCharge={setSelectedHemmingCharge}
                          isLoaded={isLoaded}
                          intl={intl}
                          isStoreDataLoading={false}
                        />
                      )}
                    </>
                  )}

                  <Box display={'flex'} gap={'16px'} paddingTop={0} paddingBottom={3}>
                    <Box
                      display={'flex'}
                      gap={'4px'}
                      justifyContent={'left'}
                      alignItems={'center'}
                      onClick={handleOpenSizeChartModal}
                      cursor="pointer"
                    >
                      <Image src="/ruler.svg" w={4} h={4} alt="Ruler Icon" />
                      <Box fontSize={'14px'} textStyle="body-75" fontWeight={600} textDecoration="underline">
                        {intl.formatMessage({
                          id: 'text.sizeChart.title',
                          defaultMessage: 'Size Chart',
                          values: { brandName: '' },
                        })}
                      </Box>
                    </Box>
                    {isSizeChartModalOpen && (
                      <SizeChartModal
                        isOpen={isSizeChartModalOpen}
                        onClose={handleCloseSizeChartModal}
                        slug={sizeChartSlugNameArray}
                        messageID="text.sizeChart.title"
                        brandName={productBrandName}
                      />
                    )}
                    {showDenimModal && (
                      <Box
                        display={'flex'}
                        gap={'4px'}
                        justifyContent={'left'}
                        alignItems={'center'}
                        onClick={handleOpenDenimModal}
                        cursor="pointer"
                      >
                        <Image src="/pants.svg" w={4} h={4} alt="Pants Icon" />
                        <Box fontSize={'14px'} textStyle="body-75" fontWeight={600} textDecoration="underline">
                          {intl.formatMessage({
                            id: 'text.sizeChart.denim',
                            defaultMessage: 'Denim Guide',
                            values: { brandName: '' },
                          })}
                        </Box>
                      </Box>
                    )}
                    {isDenimModalOpen && (
                      <SizeChartModal
                        isOpen={isDenimModalOpen}
                        onClose={handleCloseDenimModal}
                        slug={denimSlugNameArray}
                        messageID="text.sizeChart.denim"
                        brandName={productBrandName}
                      />
                    )}

                    {isRiseModalOpen && (
                      <SizeChartModal
                        isOpen={isRiseModalOpen}
                        onClose={handleCloseRiseModal}
                        slug={riseSlugNameArray}
                        messageID="text.sizeChart.rise.table"
                        brandName={productBrandName}
                      />
                    )}
                  </Box>

                  <SkeletonText isLoaded={isLoaded}>
                    {main}

                    <Button
                      size="lg"
                      variant={'ghost'}
                      width={'full'}
                      onClick={handleViewFullDetails}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent',
                        },
                        '&:active': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <Box textStyle={'ui-button-200'}>
                        {intl.formatMessage({
                          id: 'action.viewFullDetails',
                        })}
                      </Box>
                    </Button>
                    {(isInStock || isLowStock || isBackordered || isDropShipProduct) && areAllAttributesSelected && (
                      <Box mb={2}>
                        <Text
                          textStyle="heading-desktop-75"
                          tabIndex={0}
                          aria-label={formatMessage({ id: 'product.availability.text' })}
                        >
                          {formatMessage({ id: 'product.availability.text' })}
                        </Text>
                      </Box>
                    )}
                    <Box mb={2}>
                      <StockMessage
                        isInStock={isInStock}
                        isLowStock={isLowStock}
                        isBackordered={isBackordered}
                        availabilityDate={availabilityDate}
                        firstAvailableChildProduct={firstAvailableChildProduct}
                        isDonationPdp={isDonationPdp}
                        isGiftCardPdp={isGiftCardPdp}
                        areAllAttributesSelected={areAllAttributesSelected}
                        isInCart={isInCart}
                      />
                    </Box>
                    {isDropShipProduct && <DropShipProductMessage />}
                    {isShowPromoExclusion && !hasEdsPromotion && (
                      <Box
                        marginTop={3}
                        role="textbox"
                        aria-label="Promotion Exclusion Information"
                        tabIndex={0}
                        textStyle={'body-75'}
                        color={'text'}
                        dangerouslySetInnerHTML={{ __html: promoExclusionMessage }}
                      />
                    )}
                  </SkeletonText>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Scene7ViewerScript
        isOpen={isOpen}
        onClose={onClose}
        productVideos={productVideos}
        currentImages={currentImages}
        currentImageIndex={currentImageIndex}
      />
    </>
  )
}

const ImageSection = ({ productId, images, videos, onClick, updateCurrentImageIndex }) => {
  const imagesAndVideos = [...images, ...videos]

  return (
    <Container
      position="relative"
      display="inline-block"
      maxW="397px"
      sx={{
        '.chakra-image': {
          pointerEvents: 'none',
          cursor: 'default',
        },
        '.chakra-aspect-ratio': {
          cursor: 'default',
        },
      }}
    >
      <Gallery
        key={productId}
        aspectRatio={1}
        rootProps={{
          overflow: 'hidden',
          width: '100%',
          borderRadius: 'base',
        }}
        imagesAndVideos={imagesAndVideos}
        onClick={(index) => {}}
        notifyCurrentSlideIndex={updateCurrentImageIndex}
      />
    </Container>
  )
}

const extractVideosFromParentProduct = (product: Product) => {
  const videoPattern = /_vid\d+$/ // Videos will end with _vid1, _vid2, etc.
  const videos: ImagesAndVideos[] = []

  if (product?.variants?.[0]?.images) {
    // Product videos are only stored in the Master variant
    product.variants[0].images.forEach((image) => {
      if (videoPattern.test(image)) {
        videos.push({ src: image, type: 'video' })
      }
    })
  }

  return videos
}

export default QuickViewProductDetailsLayout
