export const pdpGiftCardTextSelectorsValue = {
  amounts: {
    attribute: {
      label: 'Amount',
      value: '',
    },
    options: [
      {
        value: '$25',
        inStock: true,
        priceGroup: 25,
      },
      {
        value: '$50',
        inStock: true,
        priceGroup: 50,
      },
      {
        value: '$100',
        inStock: true,
        priceGroup: 100,
      },
      {
        value: '$250',
        inStock: true,
        priceGroup: 250,
      },
      {
        value: '$500',
        inStock: true,
        priceGroup: 500,
      },
    ],
  },
}

export const pdpDonationTextSelectorsValue = {
  amounts: {
    attribute: {
      label: 'Amount',
      value: '',
    },
    options: [
      {
        value: '$1',
        inStock: true,
        priceGroup: 1,
      },
      {
        value: '$5',
        inStock: true,
        priceGroup: 5,
      },
      {
        value: '$10',
        inStock: true,
        priceGroup: 10,
      },
    ],
  },
}

export const MAX_DONATION_AMOUNT = 999
export const MAX_GIFT_CARD_AMOUNT = 500
