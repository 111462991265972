import { NEXT_PUBLIC_SITE_URL } from '../../../composable/components/general/constants'

type BuildPDPSchemaParams = {
  description: string
  title: string
  breadcrumbs: { label: string; link: string }[]
}

export const buildSchemaForPDP = ({ breadcrumbs, description, title }: BuildPDPSchemaParams) => [
  {
    '@context': 'https://schema.org',
    '@type': 'Product',
    description,
    name: title,
  },
  {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': NEXT_PUBLIC_SITE_URL + item.link,
        name: item.label,
      },
    })),
  },
]

type BuildPLPSchemaParams = {
  breadcrumbs: { label: string; link: string }[]
}

export const buildSchemaForPLP = ({ breadcrumbs }: BuildPLPSchemaParams) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': NEXT_PUBLIC_SITE_URL + item.link,
      name: item.label,
    },
  })),
})
