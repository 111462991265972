import React, { useEffect, useRef, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  ModalCloseButton,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { ContentstackRichText } from '../contentstack/rich-text'
import useContentstack from '../hooks/useContentstack'

const COMPONENT_RICH_TEXT = 'rich_text'

const SizeChartModalComponent = ({ isOpen, onClose, slug, messageID, brandName, category = null }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { getEntryBySlug, isLoading } = useContentstack()
  const [responseData, setResponseData] = useState(null)
  const hasFetched = useRef(false)
  const modalSize = useBreakpointValue({ base: 'full', md: 'lg' })
  const [isBrandSpecificModal, setIsBrandSpecificModal] = useState(false)
  const initialFocusRef = useRef(null)
  useEffect(() => {
    const fetchContent = async () => {
      const [genericSlug, brandSpecificSlug] = slug
      let data = await getEntryBySlug(COMPONENT_RICH_TEXT, brandSpecificSlug)

      if (!!data) {
        setIsBrandSpecificModal(true)
      } else {
        data = await getEntryBySlug(COMPONENT_RICH_TEXT, genericSlug)
        setIsBrandSpecificModal(false)
      }

      if (data?._embedded_items?.json_rte && Array.isArray(data._embedded_items.json_rte) && category) {
        const categoryKeywords = category.flatMap((item) => {
          const link = item.link.toLowerCase()
          return link.includes('-') && !/^t-shirts?$/.test(link) ? link.split('-') : [link]
        })

        let matchFound = false

        const updatedItems = data._embedded_items.json_rte.map((item) => {
          if (item._content_type_uid === 'component_accordion') {
            const titleLower = item.accordion_title?.toLowerCase() || ''
            const titleWords = titleLower.split(/\W+/)

            const isMatch = categoryKeywords.some((keyword) => {
              return titleWords.some((word) => word.includes(keyword) || keyword.includes(word))
            })

            if (isMatch) {
              matchFound = true
              return { ...item, initial_state_is_open: true }
            }
          }
          return item
        })

        if (!matchFound && categoryKeywords.some((keyword) => keyword.includes('shirt'))) {
          const fallbackItems = updatedItems.map((item) => {
            const titleLower = item.accordion_title?.toLowerCase() || ''

            if (titleLower.includes('tops') || titleLower.includes('outerwear')) {
              return { ...item, initial_state_is_open: true }
            }
            return item
          })

          data = { ...data, _embedded_items: { ...data._embedded_items, json_rte: fallbackItems } }
        } else {
          data = { ...data, _embedded_items: { ...data._embedded_items, json_rte: updatedItems } }
        }
      }

      setResponseData(data)
    }

    if (isOpen && !hasFetched.current) {
      fetchContent()
      hasFetched.current = true
    }
  }, [getEntryBySlug, isOpen, slug, category])

  useEffect(() => {
    if (!isOpen) {
      hasFetched.current = false
    }
  }, [isOpen])

  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      autoFocus={false}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent width={'64rem !important'} mx={2} my={4}>
        <Flex justifyContent="center" alignItems="center" position="relative" borderBottom="1px solid #E1E5E8">
          <ModalHeader
            fontSize="16px"
            textAlign="center"
            tabIndex={0}
            fontWeight={'600'}
            color="#212529"
            flex="1"
            ref={initialFocusRef}
          >
            {isBrandSpecificModal
              ? formatMessage({ id: messageID, defaultMessage: 'Size Chart', values: { brandName } })
              : formatMessage({ id: messageID, defaultMessage: 'Size Chart', values: { brandName: '' } })}
          </ModalHeader>
          <ModalCloseButton
            size={'lg'}
            right="8px"
            _hover={{ backgroundColor: 'none !important', opacity: '0.7 !important' }}
          />
        </Flex>
        <Box>{responseData && <ContentstackRichText {...responseData} padding="24px" />}</Box>
      </ModalContent>
    </Modal>
  )
}
const SizeChartModal = React.memo(SizeChartModalComponent)

export default SizeChartModal
